import { ANALYTICS_EVENT_TYPES, ANALYTICS_LINK_TYPES, triggerLinkEvent } from 'analytics/Analytics';
import { handleRedirectLinks } from 'common/link/redirectLinkHelper';
import { BASE_PATH, CMS_REDIRECT_URL_KEYS } from 'constants/API';
import useBreakpoint from 'hooks/useBreakpoint';
import useRedirectClick from 'hooks/useRedirectClick';
import PropTypes from 'prop-types';
import { createElement, memo, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { logWarning } from 'utils/logging';
import { isLinkUrl, isRedirectUrl, onNavigate } from 'utils/routing';
import { keyify } from 'utils/string';

const Content = ({
  anchorClassName,
  ariaLabel,
  buttonClassName,
  className,
  config,
  isAnchorStyleAdded = true,
  type,
  ...rest
}) => {
  const useRedirectClickValues = useRedirectClick();
  const navigate = useNavigate();
  const { breakpoint } = useBreakpoint();
  const parentRef = useRef();
  const addButtonAnchorStyles = useCallback(
    (parentRefValue) => {
      parentRefValue.current?.querySelectorAll('a, button').forEach((element) => {
        const newElement = element;
        if (newElement?.isProcessed) {
          return;
        }
        newElement.isProcessed = true;
        const linkStyles =
          newElement.nodeName === 'A'
            ? `viking-button btn btn-link link-${breakpoint}`
            : `viking-button btn btn-secondary-blue btn-${breakpoint}`;
        const classNames = (newElement.nodeName === 'A' ? anchorClassName : buttonClassName) || '';
        [...(isAnchorStyleAdded ? linkStyles.split(' ') : []), ...classNames.trim().split(' ')].forEach(
          (newClassName) => {
            if (newClassName) {
              newElement.classList.add(newClassName);
            }
          }
        );
        if (!ariaLabel) {
          newElement.setAttribute('aria-label', newElement.textContent);
        }
        if (!newElement.getAttribute('rel')) {
          newElement.setAttribute('rel', 'noopener noreferrer');
        }

        if (newElement.tagName === 'A') {
          const relativeSeparator = newElement.href?.indexOf('[API_PATH]') > -1 ? '' : '/';
          let redirectUrl = newElement.href.replace(`${window.location.origin}${BASE_PATH}/`, relativeSeparator);
          if (redirectUrl?.endsWith('/')) {
            redirectUrl = redirectUrl.slice(0, -1);
          }
          if (isRedirectUrl(redirectUrl)) {
            newElement.href = null;
            newElement.onclick = (event) => {
              handleRedirectLinks({ isRedirectModalEnabled: true, redirectUrl, useRedirectClickValues });
              event.preventDefault();
            };
            if (redirectUrl === CMS_REDIRECT_URL_KEYS.rewards || redirectUrl === CMS_REDIRECT_URL_KEYS.marketing) {
              newElement.classList.add('unlink');
            }
          } else if (isLinkUrl(redirectUrl)) {
            newElement.onclick = (event) => {
              triggerLinkEvent({
                event: ANALYTICS_EVENT_TYPES.BUTTON_CLICK,
                linkText: keyify(newElement.textContent),
                linkType: ANALYTICS_LINK_TYPES.LINK,
              });
              onNavigate({ navigate, url: redirectUrl });
              event.preventDefault();
              return false;
            };
          }
        }
      });
    },
    [anchorClassName, ariaLabel, breakpoint, buttonClassName, isAnchorStyleAdded, navigate, useRedirectClickValues]
  );

  useEffect(() => addButtonAnchorStyles(parentRef), [addButtonAnchorStyles, parentRef]);

  if (config?.html) {
    if (type) {
      return createElement(type, {
        'aria-label': ariaLabel,
        className,
        dangerouslySetInnerHTML: {
          __html: config.html,
        },
        ref: parentRef,
        ...rest,
      });
    }
    logWarning('Content component - config.html is empty.', config);
    return config.html;
  }
  logWarning('Content component - config.html is empty.', {
    anchorClassName,
    ariaLabel,
    buttonClassName,
    className,
    config,
    type,
    ...rest,
  });
  return null;
};

Content.propTypes = {
  anchorClassName: PropTypes.string,
  ariaLabel: PropTypes.string,
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  config: PropTypes.shape({
    html: PropTypes.string,
  }).isRequired,
  isAnchorStyleAdded: PropTypes.bool,
  type: PropTypes.oneOf([
    'abbr',
    'article',
    'aside',
    'blockquote',
    'div',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'i',
    'label',
    'li',
    'p',
    'span',
    'strong',
    'td',
    'th',
  ]),
};

export default memo(Content);

import { LogLevel } from '@azure/msal-browser';
import { BASE_PATH } from 'constants/API';
import { B2C_API_URI, B2C_CLIENT_ID, B2C_DOMAIN, B2C_TENANT_ID } from 'constants/ENV';
import { logDebug, logError, logInfo, logWarning } from 'utils/logging';

const B2C_POLICIES = Object.freeze({
  SISU: 'B2C_1A_TA_PASSWORDLESSSISU',
  SISU_PWD: 'B2C_1A_TA_SIGNINSIGNUP',
  CHG_PWD: 'B2C_1A_TA_PASSWORDCHANGE',
  CHG_EML: 'B2C_1A_TA_CHANGEEMAIL',
});

const B2C_AUTHORITY_BASE = `https://${B2C_DOMAIN}/${B2C_TENANT_ID}`;
// const B2C_AUTHORITY = `https://${B2C_AUTHORITY_BASE}/oauth2/v2.0/authorize?client_id=${B2C_CLIENT_ID}&p=[POLICY]&nonce=defaultNonce&redirect_uri[REDIRECT_URI]&scope=openid&response_type=id_token&prompt=login`;

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: B2C_POLICIES.SISU,
    signUpSignInWithPwd: B2C_POLICIES.SISU_PWD,
    changePassword: B2C_POLICIES.CHG_PWD,
    changeUserName: B2C_POLICIES.CHG_EML,
  },
  authorities: {
    signUpSignIn: {
      authority: `${B2C_AUTHORITY_BASE}/${B2C_POLICIES.SISU}`,
    },
    signUpSignInWithPwd: {
      authority: `${B2C_AUTHORITY_BASE}/${B2C_POLICIES.SISU_PWD}`,
    },
    changePassword: {
      authority: `${B2C_AUTHORITY_BASE}/${B2C_POLICIES.CHG_PWD}`,
    },
    changeUserName: {
      authority: `${B2C_AUTHORITY_BASE}/${B2C_POLICIES.CHG_EML}`,
    },
  },
  authorityDomain: B2C_DOMAIN,
};

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: B2C_CLIENT_ID,
    authorities: {
      signUpSignIn: `${B2C_AUTHORITY_BASE}/${B2C_POLICIES.SISU}`,
      signUpSignInWithPwd: `${B2C_AUTHORITY_BASE}/${B2C_POLICIES.SISU_PWD}`,
      changePassword: `${B2C_AUTHORITY_BASE}/${B2C_POLICIES.CHG_PWD}`,
      changeUserName: `${B2C_AUTHORITY_BASE}/${B2C_POLICIES.CHG_EML}`,
    },
    authority: b2cPolicies.authorities.signUpSignInWithPwd.authority,
    scopes: [`${B2C_API_URI}/profile.read`, `${B2C_API_URI}/profile.edit`, 'openId', 'profile', 'offline_access'],
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: BASE_PATH,
    postLogoutRedirectUri: BASE_PATH,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  scopes: [`${B2C_API_URI}/profile.read`, `${B2C_API_URI}/profile.edit`, 'openId', 'profile', 'offline_access'],
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            logError(message);
            break;
          case LogLevel.Info:
            logInfo(message);
            break;
          case LogLevel.Verbose:
            logDebug(message);
            break;
          case LogLevel.Warning:
            logWarning(message);
            break;
          default:
        }
      },
    },
  },
};

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
  scopes: ['openid', 'profile', 'offline_access'],
};

/**
 * Enter here the coordinates of your web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig = {
  scopes: [`${B2C_API_URI}/profile.read`, `${B2C_API_URI}/profile.edit`, 'openId', 'profile', 'offline_access'],
  uri: B2C_AUTHORITY_BASE,
};

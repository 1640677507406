import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { appInsightsHelper } from '@viking-eng/telemetry';
import { BASE_PATH, MAX_RETRIES, UNHANDLED_ENDPOINTS, USER_CANCELED_STATUSES } from 'constants/API';
import { TAP_API_BASE_URL } from 'constants/ENV';
import FEATURE_FLAGS from 'constants/FEATURE_FLAGS';
import ROUTES from 'constants/ROUTES';
import { getRequestHeaders } from 'utils/httpsUtils';
import { logError } from 'utils/logging';
import { base64Decode } from 'utils/string';

const TAG_TYPES = Object.freeze({
  cms: 'CMS',
});

const baseQuery = fetchBaseQuery({
  baseUrl: TAP_API_BASE_URL,
  prepareHeaders: (headers, api) => {
    const { auth } = api.getState();
    const headerObject = getRequestHeaders({
      appInsightsSessionId: appInsightsHelper?.getSessionInfo()?.session?.id || '',
      auth,
    });
    Object.keys(headerObject).forEach((key) => headers.set(key, headerObject[key]));
    return headers;
  },
});

const baseQueryWithTelemetry = async (args, api, extraOptions) => {
  let newArgs = args.then ? await args : args;
  if (newArgs.url) {
    newArgs.url = newArgs.url.includes('http') ? newArgs.url : `${TAP_API_BASE_URL}/${newArgs.url}`;
  } else {
    newArgs = newArgs?.includes('http') ? newArgs : `${TAP_API_BASE_URL}/${newArgs}`;
  }

  const result = await baseQuery(newArgs, api, extraOptions);

  try {
    const startDate = new Date();
    const appInsightsContextSessionId = appInsightsHelper?.getSessionInfo()?.session?.id || '';
    const traceparent = result.meta.request.headers.get('traceparent');
    const requestGroupId = JSON.parse(base64Decode(result.meta.request.headers.get('appinsights')))?.requestGroupId;

    if (appInsightsHelper) {
      appInsightsHelper.handleTelemetry({
        duration: Math.abs(startDate - new Date()),
        id: traceparent,
        options: { method: result.meta.request.method },
        requestGroupId,
        resource: newArgs.url || newArgs,
        response: {
          data: result.data || {},
          headers: result.meta.request.headers,
          json: () => Promise.resolve(result.data),
          ok: result.error ? false : result.meta.response.status === 200,
          status: result.error ? result.error.status : result.meta.response.status,
          statusText: result.error ? result.error.error : result.meta.response.statusText,
          url: newArgs.url || newArgs,
        },
        sessionId: appInsightsContextSessionId,
      });
    }
  } catch (error) {
    logError('App Insights - handleTelemetry', error);
  }
  if (UNHANDLED_ENDPOINTS.includes(api?.endpoint)) {
    if ([400, 404].includes(result.error?.status)) {
      retry.fail(result.error);
    }
  } else if (USER_CANCELED_STATUSES.includes(result.error?.status)) {
    // Do not retry if the call was interrupted by user
    retry.fail(result.error);
  }
  return result;
};

const backoffMaxTryHandler = (attempt = 0, maxRetries = MAX_RETRIES) => {
  const maintenanceUrl = `${BASE_PATH}${ROUTES.maintenance.url}`;
  const pageErrorUrl = `${BASE_PATH}${ROUTES.pageError.url}`;
  if (
    attempt === maxRetries &&
    !window.location.href.includes(maintenanceUrl) &&
    !window.location.href.includes(pageErrorUrl) &&
    FEATURE_FLAGS.isApiMaxTryErrorHandlerEnabled
  ) {
    window.location.href = pageErrorUrl;
  }
};

const baseQueryWithRetry = retry(baseQueryWithTelemetry, { backoff: backoffMaxTryHandler, maxRetries: MAX_RETRIES });

const api = createApi({
  baseQuery: baseQueryWithRetry,
  endpoints: () => ({}),
  reducerPath: 'tapApi',
  tagTypes: Object.values(TAG_TYPES),
});

export default api;

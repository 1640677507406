import { BrowserUtils, EventType, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import api from 'app/services/tap/api';
import { clearAuth, setAuth } from 'app/slices/auth/authSlice';
import {
  AUTH_ERROR_CODES,
  AUTH_LOGIN_MAX_AUTO_TRIES,
  AUTH_USER_TYPES,
  B2C_AUTH_ERROR_CODES,
} from 'common/auth/AUTH_CONSTANTS';
import NavigationClient from 'common/auth/NavigationClient';
import { loginRequest as authConfigLoginRequest, b2cPolicies, msalConfig } from 'configs/authConfig';
import { BROADCAST_CHANNEL_VALUES } from 'constants/CONFIG';
import { B2C_DOMAIN } from 'constants/ENV';
import { IS_LOCAL_AUTH_LOGGING_ENABLED } from 'constants/LOGGING';
import ROUTES from 'constants/ROUTES';
import { MYJOURNEY_PATH } from 'constants/STRINGS';
import { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeAllCookies } from 'utils/cookies';
import { addLoader, removeLoaderWithTimer } from 'utils/loader';
import { localLogger, logError } from 'utils/logging';
import { getRouteFromPathname, onNavigate } from 'utils/routing';

const useAuth = () => {
  const { inProgress, instance: msalInstance } = useMsal();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const isInitialLogin = useRef(false);
  const isLoggingIn = useRef(false);
  const loginTries = useRef(0);
  const broadcastChannel = useMemo(() => new BroadcastChannel(BROADCAST_CHANNEL_VALUES.accountRemoved), []);

  const changeEmail = async () => {
    await msalInstance.acquireTokenRedirect(b2cPolicies.authorities.changeUserName);
  };

  const changePassword = async () => {
    await msalInstance.acquireTokenRedirect(b2cPolicies.authorities.changePassword);
  };

  const getAccount = () => {
    const activeAccount = msalInstance.getActiveAccount();
    if (activeAccount?.environment === B2C_DOMAIN) {
      return activeAccount;
    }
    return msalInstance.getAllAccounts()?.find(({ environment }) => environment === B2C_DOMAIN);
  };

  const getIsRouteAuthorized = ({ offices }) => (offices.length ? offices.includes(auth.agency.office) : false);

  const getLoginTries = () => loginTries.current;

  const getToken = async ({ authority, callingFunction = '' } = {}) => {
    localLogger(`useAuth.js - getToken from ${callingFunction}`, IS_LOCAL_AUTH_LOGGING_ENABLED);
    const request = {
      ...msalConfig,
      account: auth.msal.account,
    };
    if (authority) {
      request.authority = authority;
    }
    const token = await msalInstance.acquireTokenSilent(request);
    return token;
  };

  const msalEventCallbackHandleError = async ({ event }) => {
    const { error } = event;
    const { errorCode, errorMessage } = error;
    logError('AUTH ERROR', JSON.stringify(event));
    if (
      error instanceof InteractionRequiredAuthError ||
      (errorCode === AUTH_ERROR_CODES.ACCESS_DENIED && errorMessage.includes(B2C_AUTH_ERROR_CODES.CANCEL_ACTION))
    ) {
      if (!isLoggingIn.current) {
        isLoggingIn.current = true;
        await msalInstance.loginRedirect(msalConfig);
        isLoggingIn.current = false;
      }
    } else if (
      errorCode === AUTH_ERROR_CODES.INVALID_GRANT &&
      errorMessage.includes(B2C_AUTH_ERROR_CODES.INCORRECT_POLICY)
    ) {
      const matches = errorMessage.match(/Expected Value : (B2C_[a-zA-Z0-9_]*)/i);
      if (matches.length > 1) {
        const requestedPolicy = matches[1].toUpperCase();
        const authorityWithPolicy = Object.values(b2cPolicies.authorities).find((authority) =>
          authority.authority.toUpperCase().includes(requestedPolicy)
        );
        if (!isLoggingIn.current) {
          isLoggingIn.current = true;
          await msalInstance.loginRedirect({ ...msalConfig, authority: authorityWithPolicy?.authority });
          isLoggingIn.current = false;
        }
      } else {
        logError('AUTH ERROR - Event logged above was unhandled');
      }
    } else if (errorCode === AUTH_ERROR_CODES.AUTHORITY_MISMATCH) {
      broadcastChannel.postMessage({ name: BROADCAST_CHANNEL_VALUES.accountRemoved });
      await logout({ callingFunction: 'useAuth.jsx - msalEventCallbackHandleError 3' });
    } else {
      logError('AUTH ERROR - Event logged above was unhandled');
    }
  };

  const msalEventCallbackHandleSuccess = async ({ event, initializeCallback }) => {
    if (event.eventType === EventType.ACCOUNT_REMOVED) {
      broadcastChannel.postMessage({ name: BROADCAST_CHANNEL_VALUES.accountRemoved });
      await logout({ callingFunction: 'useAuth.jsx - msalEventCallbackHandleSuccess' });
    } else if (
      [EventType.LOGIN_SUCCESS, EventType.ACQUIRE_TOKEN_SUCCESS, EventType.SSO_SILENT_SUCCESS].includes(event.eventType)
    ) {
      const loginAccount = event?.payload?.account || {};
      if (loginAccount?.idTokenClaims?.UserType && loginAccount?.idTokenClaims?.UserType !== AUTH_USER_TYPES.TA) {
        window.location.replace(MYJOURNEY_PATH);
      }
      if (loginAccount) {
        msalInstance.setActiveAccount(loginAccount);
        initializeCallback({ account: loginAccount });
      } else {
        initializeCallback({ account: getAccount() });
      }
    } else {
      initializeCallback({ account: getAccount() });
    }
  };

  const msalEventCallback = ({ initializeCallback }) => {
    return async (event) => {
      if (event.error) {
        msalEventCallbackHandleError({ event });
      } else {
        msalEventCallbackHandleSuccess({ event, initializeCallback });
      }
    };
  };

  const initialize = async (initializeCallback) => {
    try {
      const route = getRouteFromPathname();
      msalInstance.enableAccountStorageEvents();
      msalInstance.addEventCallback(
        msalEventCallback({
          initializeCallback,
        })
      );
      await msalInstance.handleRedirectPromise();
      const currentAccount = getAccount();
      if (currentAccount) {
        msalInstance.setActiveAccount(currentAccount);
      }
      if (!currentAccount && !route.isPublic) {
        if (!isInitialLogin.current) {
          isInitialLogin.current = true;
          await msalInstance.ssoSilent(msalConfig);
        }
      }
    } catch (error) {
      if ([AUTH_ERROR_CODES.INVALID_GRANT].includes(error.errorCode)) {
        // Expected if user doing anything other than password login. Handled in the msalEventCallback.
      } else {
        logError('AUTH ERROR - Unhandled on initialize', error);
      }
    }
  };

  const integrateRouting = (navigate) => {
    const navigationClient = new NavigationClient(navigate);
    msalInstance.setNavigationClient(navigationClient);
  };

  const login = async ({
    callingFunction = '',
    isPreCheckRequired = true,
    loginRequest = authConfigLoginRequest,
  } = {}) => {
    localLogger(`useAuth.js - Logging in from ${callingFunction}`, IS_LOCAL_AUTH_LOGGING_ENABLED);
    if (isPreCheckRequired) {
      const isMaxLoginReached = loginTries.current >= AUTH_LOGIN_MAX_AUTO_TRIES;
      loginTries.current += 1;
      if (!isMaxLoginReached) {
        if (inProgress === InteractionStatus.None) {
          await msalInstance.handleRedirectPromise();
          const account = msalInstance.getActiveAccount();
          if (!account) {
            await msalInstance.loginRedirect(loginRequest);
          }
        }
      } else {
        loginTries.current = 0;
        await logout({ callingFunction: 'useAuth.js - login 1' });
      }
    } else if (inProgress === InteractionStatus.None) {
      await msalInstance.loginRedirect(loginRequest);
    } else {
      loginTries.current = 0;
      await logout({ callingFunction: 'useAuth.js - login 2' });
    }
  };

  const logout = async ({ callingFunction = '' } = {}) => {
    localLogger(`useAuth.js - Logging out from ${callingFunction}`, IS_LOCAL_AUTH_LOGGING_ENABLED);
    addLoader();
    try {
      dispatch(clearAuth());
      dispatch(api.util.resetApiState());
      removeAllCookies();
      localStorage.clear();
      localStorage.setItem('logout-event', 'started');
      await msalInstance.logoutRedirect({
        account: msalInstance.getActiveAccount(),
        onRedirectNavigate: () => {
          return !BrowserUtils.isInIframe();
        },
      });
      await msalInstance.clearCache();
    } catch (error) {
      logError('useAuth - logout', { error });
      onNavigate(ROUTES.maintenance.url);
    } finally {
      await removeLoaderWithTimer();
    }
  };

  const updateAgent = (agent) => {
    dispatch(setAuth({ agent, callingFunction: 'useAuth.js - updateAgent' }));
  };

  return {
    changeEmail,
    changePassword,
    getAccount,
    getIsRouteAuthorized,
    getLoginTries,
    getToken,
    initialize,
    integrateRouting,
    login,
    logout,
    updateAgent,
  };
};

export default useAuth;

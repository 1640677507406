/* eslint-disable react/jsx-filename-extension */
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import livePerson from '@viking-eng/live-person';
import AnalyticsSite from 'common/analytics/AnalyticsSite';
import AuthInitializer from 'common/auth/AuthInitializer';
import RootError from 'common/error/RootError';
import CommonWrapper from 'common/layout/CommonWrapper';
import PageLoading from 'common/pageLoading/PageLoading';
import { msalConfig } from 'configs/authConfig';
import { init as mockInit } from 'mocks/browser';
import { lazy, StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import 'utils/appInsights';
import { logError } from 'utils/logging';
import { createStore } from './app/store';

const App = lazy(() => import('app/App'));
const CookiesCheck = lazy(() => import('common/cookiesCheck/CookiesCheck'));

(async () => {
  await mockInit();
  const msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();
  const { persistor, store } = createStore({ msalInstance });

  if (!livePerson) {
    // eslint-disable-next-line no-console
    console.warn('LivePerson is not available.');
  }

  createRoot(document.getElementById('root')).render(
    <ReactErrorBoundary FallbackComponent={RootError} onError={(error, info) => logError('App', { error, info })}>
      <StrictMode>
        <AnalyticsSite>
          <Suspense fallback={null}>
            <Provider store={store}>
              <MsalProvider instance={msalInstance}>
                <AuthInitializer>
                  <CookiesCheck>
                    <PersistGate loading={<PageLoading />} persistor={persistor}>
                      <CommonWrapper>
                        <App store={store} />
                      </CommonWrapper>
                    </PersistGate>
                  </CookiesCheck>
                </AuthInitializer>
              </MsalProvider>
            </Provider>
          </Suspense>
        </AnalyticsSite>
      </StrictMode>
    </ReactErrorBoundary>
  );
})();
